var ScreenView;

ScreenView = (function() {
  function ScreenView() {
    $('form').on('submit', (function(_this) {
      return function() {
        return !_this.validate();
      };
    })(this));
    $('button[type="submit"]').on('click', (function(_this) {
      return function(event) {
        if (event.metaKey || event.altKey) {
          return randomChoices();
        }
      };
    })(this));
  }

  ScreenView.prototype.validate = function() {
    var invalid;
    invalid = false;
    $('.poll li').removeClass('invalid');
    $('.poll-validation-failed').addClass('hidden');
    $('.poll li').each(function() {
      if (!$(this).find('input:checked').length) {
        $(this).addClass('invalid');
        return invalid = true;
      }
    });
    if (invalid) {
      $('.poll-validation-failed').removeClass('hidden');
    }
    return invalid;
  };

  return ScreenView;

})();

module.exports = ScreenView;
