window.ResultsView = require('./views/results-view');

window.ScreenView = require('./views/screen-view');

window.randomChoices = function() {
  return $('.poll li').each(function() {
    if (Math.random() > 0.5) {
      return $(this).find('.yes').click();
    } else {
      return $(this).find('.no').click();
    }
  });
};
