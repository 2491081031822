var ResultsView;

ResultsView = (function() {
  function ResultsView() {
    $('form').on('submit', (function(_this) {
      return function(e) {
        if ($('input[name="sendInvoice"]').is(':checked') && $('.invoice-form').is('.hidden')) {
          e.preventDefault();
          $('.results-form').addClass('hidden');
          $('.invoice-form').removeClass('hidden');
          return false;
        }
      };
    })(this));
    $('select[name="invoice[type]"]').on('change', function() {
      $('.invoice-input-company-only').addClass('hidden');
      if ($(this).val() === 'company') {
        return $('.invoice-input-company-only').removeClass('hidden');
      }
    });
    $('select[name="invoice[type]"]').change();
  }

  return ResultsView;

})();

module.exports = ResultsView;
